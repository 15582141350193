* {
  box-sizing: border-box; /* Include padding and borders in total width and height */
}

.app {
  text-align: center;
  position: relative;
  z-index: 2; /* Ensure .app content is above the background video */
  padding-bottom: 15vh; /* Start with a default padding */
  background-color: black;
  color: white;
}

.background-video {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover; /* This prevents the video from being stretched */
  z-index: -10; /* Set background video behind other content */
}

.background-image {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover; /* This prevents the video from being stretched */
  z-index: -10; /* Set background video behind other content */
}

/* Global Font Size */
html {
  font-size: 16px;
}

@media (max-width: 768px) {
  html {
    font-size: 14px; /* Smaller font size on smaller screens */
  }
}

/* Header Styling */

.header {
  position: absolute;
  top: 0;
  left: 0;
  height: 60px; /* Adjust based on the size of your logo */
  width: 100%;
  display: flex;
  align-items: center;
  background-color: transparent;
  z-index: -1; /* Higher than the background video but not covering other elements */
  padding-left: 10px; /* Space from the left edge */
  order: 2;
}

.logo {
  height: 125px; /* Adjust the size of your logo */
  position: absolute; /* Position logo absolutely within the header */
  top: 5px; /* Adjust based on header height */
  left: 10px; /* Adjust for spacing from the left edge */
}

@media (max-width: 768px) {
 .header {
    display: flex;
    justify-content: center; /* Center the child elements (logo) */
    height: 60px; /* Adjust based on the size of your logo */
    padding-left: 0; /* Remove left padding for centering */
    padding-top: 60px;
    margin-top: 60px;
    margin-bottom: 50px;
  }

  .logo {
    height: auto; /* Adjust the size of your logo */
    width: 100%; /* Make logo take the full width */
    position: relative; /* Change position to relative for proper centering */
    top: 0; /* Reset top positioning */
    left: 0; /* Reset left positioning */
    padding-top: 60px;
    padding-bottom: 20px;
  }
}

/* Navigation Styling */

.navigation {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  font-size: 28px;
  font-weight: bold;
}

@media (max-width: 768px) {
  .nav-wrapper {
  margin-top: 50px;
  padding-top: 30px;
  text-align: center;
}
}

.nav-wrapper {
  margin-top: 30px;
  text-align: center;
  order: 3;
}

.nav-button {
  padding: 20px 40px;
  margin: 0 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #00f400;
  text-decoration: none;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s, border-color 0.3s;
  flex: 1 0 80%; /* Full width buttons on small screens */
  margin-bottom: 10px; /* Add some space between buttons */
}

.main-content {
  padding-bottom: 80px;
}

@media (min-width: 769px) {
  .nav-button {
    flex: 0 0 auto; /* Auto width buttons on larger screens */
  }
}

.nav-button:hover {
  background-color: #525252;
  color: white;
}

.nav-button.active {
  background-color: #ea580c;
  border-color: #ea580c;
  color: white;
}

/* Footer Styling */
.footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  text-align: center;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footer a {
  display: block; /* Each link on its own line on small screens */
}

@media (min-width: 769px) {
  .footer {
    flex-direction: row;
    justify-content: space-around;
    margin-top: 200px;
  }

  .footer a {
    display: inline; /* Links inline on larger screens */
  }
}

.twitter-icon {
  width: 40px; /* Adjust size as needed */
  height: auto;
  margin-right: 20px; /* Space between icons and text */
}

.discord-icon {
  width: 200px; /* Adjust size as needed */
  height: auto;
  margin-right: 20px; /* Space between icons and text */
}

.tele-icon {
  width: 40px; /* Adjust size as needed */
  height: auto;
  margin-right: 20px; /* Space between icons and text */
}

.degen-icon {
  width: 80px; /* Adjust size as needed */
  height: auto;
  margin-right: 20px; /* Space between icons and text */
}

/* Dynamic Widget */

.top-container {
  display: flex;
  justify-content: flex-end;
  padding-top: 10px;
  padding-right: 10px;
  z-index: 1000;
  order: 1;
}

@media (max-width: 768px) {
  .top-container {
    display: flex;
    justify-content: center;
    padding-top: 10px;
    padding-right: 10px;
    padding-bottom: 100px;
    z-index: 1000;
  }
}

/* Balances.js */

.balance-container {
    position: fixed;
    bottom: 15%;
    left: 20%;
    width: 60%;
    display: flex;
    justify-content: space-between;
    border: 3px solid #ea580c;
    padding: 10px;
    background-color: black;
    color: white;
}

.balance-item {
    border-right: 3px solid #ea580c;
    padding-right: 10px;
    flex: 1;
    font-size: 25px;
}

.balance-item:last-child {
    border-right: none;
}

@media (max-width: 768px) {
    .balance-container {
        position: static; /* Adjust if necessary for mobile layout */
        width: 100%; /* Full width on mobile */
        display: flex;
        flex-direction: column; /* Stack items vertically */
        align-items: center; /* Center items horizontally */
        padding-top: 20px; /* Adjust padding for mobile layout */
        padding-bottom: 20px;
        margin-top: 50px;
        margin-bottom: 150px;
    }

    .balance-item {
        border-right: none; /* Remove right border in mobile view */
        padding-right: 0; /* Remove right padding in mobile view */
        padding-bottom: 10px; /* Space between items */
        width: 90%; /* Adjust as per your design needs */
        text-align: center; /* Center align the text */
    }
}

/* Presale.js */

.countdown {
  display: inline-block;
  border: 3px solid #ea580c;
  padding: 20px;
  border-radius: 5px;
  background-color: black;
  margin: 0 auto;
  text-align: center;
  color: #ffffff;
}

.pricing {
  color: #ffffff;
}

.castle-section {
    display: inline-block;
    border: 3px solid #ea580c;
    padding: 20px;
    border-radius: 5px;
    background-color: black;
    margin: 0 auto;
    text-align: center;
}

.castle-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    gap: 1rem; 
}

.input-box {
    padding: 10px;
    margin: 5px;
    border: 3px solid #ea580c;
    border-radius: 5px;
    text-align: center;
    width: 100%;
}

.presale-button {
    padding: 10px 20px;
    background-color: #ea580c;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
    margin-top: 10px;
    width: 100%;
}

.insufficient-funds {
    color: #dc2626;
}

.token-input {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
}

.token-logo {
  width: 32px;
  height: 32px;
  margin-right: 10px; /* Adjust the spacing to your preference */
}


.token-details {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.balance {
  margin-left: auto;
  font-size: 0.8rem;
  color: #ffffff;
}

.percentage-buttons button {
  margin-right: 0px;
}

.input-box {
  width: 100%;
}

.percentage-buttons {
  display: flex;
  justify-content: space-around;
  margin: 15px 0;
}

.percentage-button {
  padding: 5px 15px;
  border: none;
  border-radius: 5px;
  background-color: #e0e0e0;
  cursor: pointer;
  font-weight: bold;
  color: #ea580c;
}

.percentage-button.selected {
  background-color: #ea580c;
  color: #ffffff;
  font-weight: bold;
}
